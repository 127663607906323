import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/react"
import { color, fontStyle } from "../../components/defaults/styles/theme"
import mq from "../../components/defaults/styles/mediaquerys"
import { wrapper } from "../defaults/styles/elements"
import BackgroundImage from "gatsby-background-image-es5"
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import { useRegistration } from "../../services/typeformRegistration"
import { IconButtonBtn, IconButton } from "../defaults/styles/elements"
import Flags from "../../data/raw/flags"

const VisualHeader = ({ windowHeight }) => {
  const { registerButton, toggle } = useRegistration()
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "expo2022hero_transparent" }, ext: { eq: ".png" }) {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            quality: 90
          )
        }
      }
    }
  `)

  const heroImage = data.file ? convertToBgImage(getImage(data.file)) : ""

  const landingButton = css`
    padding: 1em 2em;
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 1em;
    &:before {
      border-radius: 10px;
    }
  `

  return (
    <section
      css={[
        css`
          background-color: ${color.richBlack};
          position: relative;
          overflow: hidden;
          min-height: ${windowHeight ? windowHeight + "px" : "100vh"};
        `,
      ]}
    >
      <div
        css={css`
          height: ${windowHeight ? windowHeight + "px" : "100vh"};
          width: 100%;
          position: absolute;
        `}
      >
        <BackgroundImage
          {...heroImage}
          style={{ position: "absolute" }}
          css={css`
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            background-position: bottom left;
            transform: scaleX(-1);
            ${mq[1]} {
              transform: scaleX(1);
            }
          `}
        ></BackgroundImage>
      </div>
      <div
        css={[
          wrapper,
          css`
            position: relative;
            margin-top: 50vh;
            color: white;
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: calc(100% - 2em);
            margin-left: 0;
            ${mq[1]} {
              margin-left: auto;
              width: 80%;
              align-items: flex-end;
              text-align: right;
              margin-right: 0;
            }
            ${mq[2]} {
              margin-right: auto;
              width: 100%;
            }
          `,
        ]}
      >
        <div
          css={css`
            background: ${color.gray800};
            border-radius: 1em;
            padding: 4px;
            display: flex;
            align-items: center;
            p {
              color: white;
              font-size: 0.8em;
              margin: 0;
              font-weight: 600;
              letter-spacing: 0.025em;
            }
          `}
        >
          <div
            css={css`
              background: ${color.plue300};
              border-radius: 1em;
              padding: 0.25em 1em;
            `}
          >
            <p>EXPO2022</p>
          </div>
          <p
            css={css`
              padding: 0.25em 1em;
            `}
          >
            July 7, 2022
          </p>
        </div>
        <h1
          css={css`
            margin-top: 1rem;
            ${fontStyle.header.landingSection};
          `}
        >
          Driving Open Innovation on the Autobahn.
        </h1>
        <div
          css={css`
            p {
              ${fontStyle.copy.regular};
              ${fontStyle.copy.landing};
            }
          `}
        >
          <p>
            Thank you so much for joining us on July 7, 2022 for a day full of
            innovation, collaboration, and inspiration. We hope you enjoyed the
            event as much as we did and that you had the chance to network and
            learn about the innovative advancements achieved by our startups and
            corporate partners this past year.
          </p>
          <p>
            To relive the special moments feel free to checkout our EXPO2022
            rearview.
          </p>
        </div>
        <div
          css={css`
            margin-top: 2rem;
          `}
        >
          {Flags.features.main_registration && !Flags.features.live && (
            <IconButtonBtn
              name={registerButton.buttonText}
              onClick={toggle}
              css={[
                landingButton,
                css`
                  color: black;
                  margin-right: 1rem;
                  &:before {
                    background: white;
                  }
                `,
              ]}
            />
          )}
          {(Flags.features.live || Flags.features.rearview) && (
            <IconButton
              name={
                Flags.features.live
                  ? "Join EXPO2022 Livestream"
                  : "EXPO2022 Rearview"
              }
              url={
                Flags.features.live
                  ? Flags.settings.live.destination
                  : "/rearview"
              }
              css={[
                landingButton,
                css`
                  color: white;
                  margin-right: 1rem;
                  display: inline-block;
                  &:before {
                    background: ${Flags.features.live
                      ? color.plue_rec
                      : color.plue300};
                  }
                `,
              ]}
            />
          )}
          <IconButton
            name="EXPO2022 Pictures"
            to="https://pictures.startup-autobahn.com/"
            css={[
              landingButton,
              css`
                display: inline-block;
                &:before {
                  background: none;
                  border: 1px solid ${color.gray400};
                }
              `,
            ]}
          />
        </div>
        {registerButton.errorMessage && (
          <p
            css={css`
              opacity: 0.5;
              color: ${color.main_light};
              font-size: 0.65em;
              line-height: 1.4em;
              margin: 2em 0 0;
              max-width: 20rem;
              a {
                color: ${color.main_light};
              }
            `}
          >
            Unfortunately we were not able to process your submitted
            application. For further details and assistance please get in touch
            with <a href="mailto:bastian@pnptc.com">bastian@pnptc.com</a>.
          </p>
        )}
      </div>
    </section>
  )
}

export default VisualHeader
